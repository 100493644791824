import Head from "next/head";

function SeoHead({ title, description, keywords, canonicalUrl }) {
  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="fb:app_id" content="826953751692351" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://email.jobsineducation.net/FacebookPost.jpg"
        />
        <meta property="og:site_name" content="Jobs in education" />
        <meta name="twitter:creator" content="@JobinEdu" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          property="twitter:image:src"
          content="https://email.jobsineducation.net/TwitterPost.jpg"
        />
        <meta name="twitter:site" content="jobsineducation" />
        {/* {botCheck && (
          <script
            async
            dangerouslySetInnerHTML={{
              __html: `
                var dataTrafficGuard = dataTrafficGuard || [];
                dataTrafficGuard.push(["property", "tg-011571-001"]);
                dataTrafficGuard.push(["event", "pageview"]);
                (function () {
                  var tg = document.createElement("script");
                  tg.type = "text/javascript";
                  tg.async = true;
                  tg.src = "//tgtag.io/tg.js?pid=tg-011571-001";
                  var s = document.getElementsByTagName("script")[0];
                  s.parentNode.insertBefore(tg, s);
                })();
              `,
            }}
          />
        )} */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
                {
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  "name": "Jobs in Education",
                  "url": "${canonicalUrl}",
                  "logo": "${process?.env?.NEXT_PUBLIC_CDN}/jie/web/images/logo-dark.svg",
                  "contactPoint": {
                    "@type": "ContactPoint",
                    "telephone": "",
                    "contactType": "technical support",
                    "areaServed": "IN",
                    "availableLanguage": "en"
                  },
                  "sameAs": [
                    "https://www.facebook.com/jobsinedu",
                    "https://www.instagram.com/jobsinedu/",
                    "https://www.linkedin.com/company/jobsineducation/"
                  ]
                }
              `,
          }}
        />
      </Head>
    </>
  );
}

export default SeoHead;
